<template>
  <div class="patient-panel">
    <div class="main-page">
      <v-row class="d-flex flex-wrap justify-content-center">
        <v-col class="card-main" cols="12" md="6">
          <v-card class="card-start pa-10 ms-10">
            <h1 class="mb-6 text-center grey--text text--darken-2 font-weight-bold" style="line-height: 40px">
              به سامانه مدیریت درمان توتیاطب خوش آمدید!
            </h1>
            <v-card-text>
              <h4 style="line-height: 40px">
                برای شروع فرآیند ثبت درخواست بر دکمه
                <b class="primary--text">ثبت درخواست در درمانگاه</b>
                کلیک کنید.
              </h4>
              <div class="btn-newRequest mt-8 pa-16" @click="goToNewRequest()">
                <h3 class="font-weight-bold">
                  <v-icon class="me-1 white--text" large
                    >mdi-plus-circle-outline</v-icon
                  >
                  ثبت درخواست در درمانگاه
                </h3>
              </div>
            </v-card-text>
          </v-card></v-col
        >
        <v-col cols="12" md="6">
          <v-img
            src="@/assets/images/patient_panel_img.png"
            width="700"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goToNewRequest(){
      this.$router.push('/patientPanel/newRequest')
    }
  }
};
</script>